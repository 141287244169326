import React from 'react';
import '../static/css/styling.css';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Router, Route, Switch } from 'react-router-dom';
import history from '../utils/history';

import Home from './home';

const theme = createTheme({
  palette: {
    primary: { light: '#445b91', main: '#0d255f', dark : '#021952', contrastText: '#ffffff'},
    secondary: { light: '#f77570', main: '#fb372f', dark : '#f51108', contrastText: '#ffffff'},
    error: { light: '#ffb1a2', main: '#ff7351', dark : '', contrastText: '#ffffff'},
    info: { light: '#75dbf9', main: '#4bc8e8', dark : '', contrastText: '#ffffff'},
    success: { light: '#73eb8a', main: '#64dc7a', dark : '', contrastText: 'ffffff'},
  },
});

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <Switch>
            <Route path="/" exact component={Home} />
            <Route path="*" component={() => "Page not found"} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}