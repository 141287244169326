import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import AddCommentIcon from '@material-ui/icons/AddComment';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import PinterestIcon from '@material-ui/icons/Pinterest';
import CallIcon from '@material-ui/icons/Call';
import MailIcon from '@material-ui/icons/Mail';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    page:{maxWidth: '1400px', margin: 'auto'},
    redText:{color: theme.palette.secondary.main, fontWeight: 'bolder', paddingRight: '10px'},
    redLine:{height: '40px', background: theme.palette.secondary.main, display: 'flex', justifyContent: 'center', alignItems: 'center'},
    captionText:{fontVariant: 'all-small-caps', fontSize: '1.5rem', fontWeight: 'bold', lineHeight: '1.1rem', color: theme.palette.secondary.main,
        [theme.breakpoints.down('sm')]: {textAlign: 'center', lineHeight: '1.5rem'}
    },
    blueLine:{background: theme.palette.primary.main, borderRadius: '5px', marginTop: '-25px', display: 'flex', alignItems: 'center', padding: '10px 20px', justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {margin:0, borderRadius: 0, padding: '10px 5px'}
    },
    containerHeading:{fontSize: '1.75rem', color: theme.palette.secondary.main, fontWeight: 'bold', textTransform: 'uppercase', padding: '0'},
    containerTagLine:{fontSize: '2.25rem', color: theme.palette.primary.main, fontWeight: 'bold', padding: '1rem 0.25rem 2rem 0.25rem',
        [theme.breakpoints.down('sm')]: {fontSize: '2.15rem', padding: '0.25rem'}
    },
    highlightWord:{color: theme.palette.secondary.main, textDecoration: 'underline'},

    logo:{width: '80%', maxWidth: '350px',
        [theme.breakpoints.down('sm')]: {maxWidth: '220px'}
    },
    contactLinkNavbar:{display: 'block', padding: '4px 10px', textDecoration: 'none',
        [theme.breakpoints.down('sm')]: {padding: '4px'}
    },
    contactIconNavbar:{fontSize: '2rem', color: 'white', padding: '0.5rem', background: theme.palette.primary.main, borderRadius: '0.25rem',
        '&:hover':{background: theme.palette.secondary.main, cursor: 'pointer'}
    },

    heroSection:{background: 'url("https://www.cymolthemes.com/html/devfox/images/slides/slider-mainbg-001.jpg")', backgroundPosition: 'bottom', backgroundRepeat: 'no-repeat', minHeight: '600px', alignItems: 'center'},
    heroHeadingBox:{background: 'rgba(255, 255, 255, 0.85)', borderLeft: '8px solid red', padding: '2.5rem',
        [theme.breakpoints.down('md')]: {border: '2px solid red', padding: '2rem 0.5rem'}
    },
    heroHeadingLineOne:{color: theme.palette.secondary.main, fontWeight: 'bolder', paddingBottom: '10px', fontSize: '1.5rem',
        [theme.breakpoints.down('md')]: {padding: '10px'}
    },
    heroHeadingLineTwo:{color: theme.palette.primary.main, paddingRight: '10px', fontSize: '66px', fontWeight: 700, lineHeight: '76px', marginBottom: '20px',
        [theme.breakpoints.down('md')]: {fontSize:'45px', lineHeight: '55px'}
    },
    heroHeadingLineThree:{color: theme.palette.grey[600], paddingRight: '10px', fontSize: '1.25rem',
        [theme.breakpoints.down('md')]: {textAlign: 'center', padding: '0px'}
    },
    heroBtn:{marginTop: '2rem', padding: '10px 40px',
        [theme.breakpoints.down('md')]: {textAlign: 'center', width: '100%'}
    },
    heroBtnText:{fontWeight: 'bolder', letterSpacing: '1.5px', wordSpacing: '3px', fontSize: '1.25rem'},
    baseBar:{padding: '4rem 10px 4rem 10px', backgroundColor: theme.palette.primary.main, background: 'linear-gradient(180deg, #0d255f 65%, #ffffff 65%)',
        [theme.breakpoints.down('md')]: {textAlign: 'center', padding: '10px'}
    },
    baseLineOne:{color: theme.palette.secondary.main, fontWeight: 'bold', fontSize: '2.25rem', textTransform:'uppercase', fontVariant: 'all-small-caps',
        [theme.breakpoints.down('md')]: {fontSize: '1.75rem'},
    },
    baseLineTwo:{color: 'white', fontWeight: 'bold', fontSize: '3.25rem',
        [theme.breakpoints.down('md')]: {fontSize: '2.25rem'},
        [theme.breakpoints.down('sm')]: {fontSize: '1.75rem', padding: '0.25rem 0 0.5rem 0'}
    },
    baseLineTwoRedText:{color: theme.palette.secondary.main},
    baseDesc:{color: theme.palette.grey[500], fontSize: '1.25rem', maxWidth: '600px', margin: 'auto', textAlign: 'justify'},
    baseBox:{margin: '10px 10px', padding: '0px', borderBottomColor: theme.palette.primary.main, borderBottomWidth: '4px', borderBottomStyle: 'solid', borderRadius: '6px', position: 'relative',
        '&:hover':{borderBottomColor: theme.palette.secondary.main, cursor: 'pointer'}
    },
    baseBoxText:{marginTop: '-4px', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'white', borderRadius: '0 0 6px 6px'},
    baseBoxImg:{maxWidth: '100%', borderRadius: '6px 6px 0 0'},
    baseBoxFirstLetter:{color: theme.palette.secondary.main, padding: '5px 0px', fontSize: '2rem', fontWeight: 'bolder', marginRight: '-5px'},
    baseBoxName:{color: theme.palette.primary.main, padding: '15px 5px', fontSize: '2rem', fontWeight: 'bolder'},
    baseStepCounter:{width: '50px', height: '50px', background: 'red', borderRadius: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', left: -10, top: -10 },

    aboutUs:{padding: '1.5rem 0 5rem 0',
        [theme.breakpoints.down('md')]: {padding: '1rem 0'}
    },
    aboutUsLineTwo:{fontVariant: 'all-small-caps', padding: '0 0.75rem 0rem 0rem', fontSize: '3.5rem', marginTop: '-0.5rem',
        [theme.breakpoints.down('md')]: {fontSize: '2rem'}
    },
    aboutUsLineThree:{color: theme.palette.grey[500], padding: '0rem 0.25rem 1.2rem 0.25rem', marginTop: '0rem', fontSize: '1.2rem'},
    aboutUsSubHeading:{color: theme.palette.secondary.main, fontSize: '1.5rem', textDecoration: 'underline', fontVariant: 'small-caps', fontWeight: 'bold'},
    aboutUsSubText:{color: theme.palette.grey[500], padding: '0.5rem 0.25rem 0.5rem 0.25rem', fontSize: '1.2rem'},

    services:{background: 'url("https://www.cymolthemes.com/html/devfox/images/bg-image/row-bgimage-2.jpg")', backgroundSize: 'contain', padding: '5rem 0 2rem 0', backgroundRepeat: 'no-repeat', textAlign: 'center'},
    servicesWhitePage:{padding: '3rem', marginTop: '3rem', background: 'white', borderRadius: '2rem', textAlign: 'left',
        [theme.breakpoints.down('md')]: {padding: '3rem 1rem', marginTop:'0.5rem'}
    },
    servicesLineThree:{fontWeight: 'bold', color: theme.palette.secondary.main, fontSize: '1rem'},
    servicesLineFour:{fontWeight: 'bold', color: theme.palette.primary.main, fontSize: '2.5rem'},
    servicesLineFive:{color: theme.palette.grey[600], paddingBottom: '1.5rem', fontSize: '1.2rem', textAlign: 'justify'},
    servicesBulletPt:{color: theme.palette.primary.main, padding: '0.25rem 0', display: 'flex'},
    servicesBulletPtIcon:{color: theme.palette.secondary.main, paddingRight: '5px', fontSize: '1rem', paddingTop: '4px'},
    serviceBoxContainer:{padding: '0 0rem 1rem 0'},
    servicesBox:{padding: '0.25rem', margin: '0.75rem', borderRadius: '0.5rem', textAlign: 'center', borderBottomWidth: '3px', borderBottomStyle: 'solid', borderBottomColor: theme.palette.primary.main, position: 'relative',
        '&:hover': {boxShadow: '0 10px 50px grey', cursor: 'pointer'
        // '&:hover':{cursor: 'pointer', boxShadow:'0px 0px 50px 0px rgba(189,176,189,1)'
            // "& $serviceBoxIcon": {color: 'white'},
            // "& $serviceBoxName": {color: 'white'},
            // "& $serviceBoxDesc": {color: 'white'}
        }
    },
    serviceBoxIcon: {fontSize: '5rem', paddingBottom: '1rem', color: theme.palette.primary.main, padding: '2rem 2rem 0rem 2rem'},
    serviceBoxName:{color: theme.palette.primary.main, fontSize: '2rem', fontWeight: 'bold', padding: 0, fontVariant:'all-small-caps'},
    serviceBoxDesc:{color: theme.palette.secondary.main, paddingBottom: '1rem', fontVariant:'all-small-caps', fontSize: '1.25rem'},

    themesContainer:{padding: '1rem 0 3rem 0', textAlign: 'center', zIndex: '99999'},
    themeCell:{padding: '10px',
        '&:hover': {boxShadow: '0 10px 50px grey', cursor: 'pointer'}
    },
    themeCellImg:{borderRadius: '1px', zIndex: 999, margin: 0, width: '100%'},
    themeName:{background: 'rgba(0,0,0, 0.6)', padding: '10px 0', fontSize: '1.75rem', fontWeight: 'bolder', textTransform: 'uppercase', color: 'white', marginTop: '-65px', zIndex: 9999, position: 'relative'},

    pwaContainer:{padding: '3rem 0', textAlign: 'center', background: theme.palette.primary.main, display: 'none'},
    pwaColumnContainer:{display: 'flex', alignItems: 'stretch'},

    pwaColImg:{order: 2, [theme.breakpoints.down('md')]: {order: 1}},
    pwaFeatureColOne:{order: 1, [theme.breakpoints.down('md')]: {order: 2}},
    pwaFeatureColTwo:{order: 3},

    pwaFeatureBox:{margin: '10px 10px', padding: '15px 25px', borderRadius: '10px', borderWidth: '5px', borderStyle: 'double', borderColor: theme.palette.secondary.main,
        '&:hover': {background: theme.palette.secondary.main, cursor: 'pointer',
            "& $pwaFeatureDesc": {color: 'white'}
        }
    },
    pwaIcon:{fontSize: '2.5rem', padding: '0', color: 'white'},
    pwaFeatureName:{fontSize: '1.5rem', textTransform: 'uppercase', fontWeight: 'bold', padding: '0.25rem 0', color: 'white'},
    pwaFeatureDesc:{textAlign: 'justify', color: theme.palette.grey[400]},

    featureContainer:{padding: '3rem 0', textAlign: 'center'},
    featureBoxContainer:{},
    featureBox:{padding: '1rem 1rem', margin: '0.5rem', borderRadius: '0.5rem', textAlign: 'left', position: 'relative', overflow: 'hidden'},
    featureName:{fontSize: '1rem', fontWeight: 'bold', color: theme.palette.primary.main, padding: '1rem 0'},
    featureDesc:{color: theme.palette.grey[500]},
    redCorner: {height: '35px', width: '20px', padding: '0 8px 10px 17px', background: 'red', display: 'none', justifyContent: 'right', alignItems: 'center', color: 'white', position: 'absolute', top: 0, right: 0, borderRadius: '0 20px 0 100px', border: '0px solid white'},
    featureBoxBg:{ opacity: '0.2', position: 'absolute', right: 5, top : 5 },

    footer:{backgroundColor: theme.palette.primary.main, background: 'linear-gradient(0deg, #0d255f 65%, #ffffff 65%)',
        [theme.breakpoints.down('md')]: {background: 'linear-gradient(0deg, #0d255f 92%, #ffffff 92%)'}
    },

    contactBanner:{background: 'white', margin: '0 0.5rem', padding: '3rem 0rem', borderRadius: '1rem', border: '2px solid red',
        [theme.breakpoints.down('md')]: {padding: '1rem 0'}
    },
    contactBox:{display: 'flex', alignItems: 'center', justifyContent: 'center', borderRight: '1px double #a19393', padding: '0.5rem',
        [theme.breakpoints.down('md')]: {justifyContent: 'left'},
    },
    contactIcon:{color: theme.palette.secondary.main, fontSize: '5rem', padding: '0 15px 0 0',
        [theme.breakpoints.down('md')]: {fontSize: '3rem'}
    },
    contactTextLineOne:{fontSize: '2rem', fontWeight: 'bolder', color:  theme.palette.primary.main,
        [theme.breakpoints.down('md')]: {fontSize: '1.5rem'}
    },
    contactTextTwo:{color:  'theme.palette.primary.main', fontWeight: 'bold', fontSize: '1.05rem'},
            
    footerContentArea:{padding: '2rem 0 1rem 0'},
    footerCol:{marginBottom: '2rem', padding: '0 0.5rem'},

    footerColOne:{order: 1, [theme.breakpoints.down('md')]: {order: 3}},
    footerColTwo:{order: 2, [theme.breakpoints.down('md')]: {order: 2}},
    footerColThree:{order: 3, [theme.breakpoints.down('md')]: {order: 1}},

    footerColHead:{color: theme.palette.secondary.main, fontWeight: 'bolder', fontSize: '1.5rem'},
    footerColLink:{color: theme.palette.grey[500], padding: '0.25rem', fontSize: '1rem',
        '&:hover': {cursor: 'pointer', color: 'white', fontSize: '1.25rem', background: theme.palette.secondary.main}
    },
    socialIcon:{color: theme.palette.secondary.main, fontSize: '1.75rem', padding: '0 0.35rem'},

    bottomLine:{background: theme.palette.secondary.main, padding: '5px 0', textAlign:'center', color: 'white'}
}));

const Home = () => {
    const classes = useStyles();
    return (
        <>
            <Hidden smDown>
                <Grid container className={`${classes.redLine}`}></Grid>
            </Hidden>
            <Grid container className={classes.page} >
                    <Grid container className={`${classes.blueLine}`} >
                        <Hidden smDown>
                            <Grid item><a href="tel:+919958344477" style={{color:'white', textDecoration: 'none', display: 'flex'}} target="_blank" rel="noreferrer"><Typography className={classes.redText} >Call Us : </Typography><Typography style={{color: 'white'}} >+91-9958344477</Typography></a></Grid>
                        </Hidden>
                        <Grid item style={{margin: 'auto'}} ><Typography className={classes.captionText} >Defining E-Commerce as EASY Commerce</Typography></Grid>
                        <Hidden smDown>
                            <Grid item ><a href="mailto:easycommercesol@gmail.com" style={{color:'white', textDecoration: 'none', display: 'flex'}} target="_blank" rel="noreferrer"><Typography className={classes.redText} >Mail Us : </Typography><Typography style={{color: 'white'}}>easycommercesol@gmail.com</Typography></a></Grid>
                        </Hidden>
                    </Grid>
            </Grid>
            <Grid container className={`${classes.page}`}>
                <Grid item xs={10} sm={8} md={6} lg={4} style={{padding: '1rem 2rem 1rem 0'}} ><img src="https://imgur.com/hb2yKRO.png" alt="" className={classes.logo} /></Grid>
                <Grid item xs={2} sm={4} md={6} lg={8} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                    <Hidden smDown>
                        <a className={classes.contactLinkNavbar} href="tel:+919958344477" alt="">
                            <Button variant="contained" color="primary" className={classes.button} startIcon={<CallIcon />}>Call Us</Button>
                        </a>
                        <a className={classes.contactLinkNavbar} href="mailto:easycommercesol@gmail.com" alt="">
                            <Button variant="contained" color="primary" className={classes.button} startIcon={<MailIcon />}>Mail Us</Button>
                        </a>
                        <a className={classes.contactLinkNavbar} href="https://wa.me/919958344477?text=Hi,%20I%20would%20like%20to%20know%20more%20about%20your%20products%20and%20services" alt="">
                            <Button variant="contained" color="primary" className={classes.button} startIcon={<WhatsAppIcon />}>Message Us</Button>    
                        </a>
                    </Hidden>
                    <Hidden mdUp>
                        <a className={classes.contactLinkNavbar} href="tel:+919958344477" alt="">
                            <IconButton className={classes.contactIconNavbar} aria-label="call"><CallIcon /></IconButton>
                        </a>
                        <a className={classes.contactLinkNavbar} href="mailto:easycommercesol@gmail.com" alt="">
                            <IconButton className={classes.contactIconNavbar} aria-label="mail"><MailIcon /></IconButton>
                        </a>
                        <a className={classes.contactLinkNavbar} href="https://wa.me/919958344477?text=Hi,%20I%20would%20like%20to%20know%20more%20about%20your%20products%20and%20services" alt="">
                            <IconButton className={classes.contactIconNavbar} aria-label="whatsapp"><WhatsAppIcon /></IconButton>
                        </a>
                    </Hidden>
                </Grid>
            </Grid>
            <Grid container className={classes.heroSection}>
                <Grid item xs={1} md={3} lg={2}></Grid>
                <Grid item xs={10} md={6} lg={5} className={classes.heroHeadingBox}>
                    <Typography className={classes.heroHeadingLineOne}>We follow</Typography>
                    <Typography className={classes.heroHeadingLineTwo}>4 Steps of <br/>E-Commerce Success</Typography>
                    <Typography className={classes.heroHeadingLineThree}>All types of tech solutions for your E-Commerce Business!</Typography>
                    <a href="tel:+919958344477" alt="" style={{textDecoration: 'none'}}>
                        <Button variant="contained" color="secondary" className={classes.heroBtn}>
                            <Typography className={classes.heroBtnText} >Get In Touch</Typography>
                        </Button>
                    </a>
                </Grid>
            </Grid>
            <Grid container className={classes.baseBar}>
                <Grid container className={classes.page} style={{paddingBottom: '2rem'}}>
                    <Grid item xs={12} md={6}>
                        <Typography className={classes.baseLineOne}>Let's define </Typography>
                        <Typography className={classes.baseLineTwo}>The <span className={classes.baseLineTwoRedText}>BASE</span> of E-Commerce</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography className={classes.baseDesc}>For any business to flourish, the BASE needs to be strong. At Easy Commerce Solutions, we have categorized all the activities of an e-commerce business into four buckets which acts as the Plan for Success having 4 steps as follows.</Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.page}>
                    <Grid item xs={12} md={6} lg={3}>
                        <Box className={classes.baseBox} boxShadow={3}>
                            <Box><img className={classes.baseBoxImg} src="https://imgur.com/60rKbD0.png" alt="" /></Box>
                            <Box className={classes.baseBoxText}><Typography className={classes.baseBoxFirstLetter}>B</Typography><Typography className={classes.baseBoxName}>UILD</Typography></Box>
                            <Box boxShadow={24} className={classes.baseStepCounter}><Typography style={{fontSize: '2.25rem', fontWeight: 'bolder', color: 'white'}} >B</Typography></Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Box className={classes.baseBox} boxShadow={3}>
                            <Box><img className={classes.baseBoxImg} src="https://imgur.com/hoHvrp4.png" alt="" /></Box>
                            <Box className={classes.baseBoxText}><Typography className={classes.baseBoxFirstLetter}>A</Typography><Typography className={classes.baseBoxName}>DVERTISE</Typography></Box>
                            <Box boxShadow={24} className={classes.baseStepCounter}><Typography style={{fontSize: '2.25rem', fontWeight: 'bolder', color: 'white'}} >A</Typography></Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Box className={classes.baseBox} boxShadow={3}>
                            <Box><img className={classes.baseBoxImg} src="https://imgur.com/WWyuEw5.png" alt="" /></Box>
                            <Box className={classes.baseBoxText}><Typography className={classes.baseBoxFirstLetter}>S</Typography><Typography className={classes.baseBoxName}>UPERVISE</Typography></Box>
                            <Box boxShadow={24} className={classes.baseStepCounter}><Typography style={{fontSize: '2.25rem', fontWeight: 'bolder', color: 'white'}} >S</Typography></Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Box className={classes.baseBox} boxShadow={3}>
                            <Box><img className={classes.baseBoxImg} src="https://imgur.com/QO8DTza.png" alt="" /></Box>
                            <Box className={classes.baseBoxText}><Typography className={classes.baseBoxFirstLetter}>E</Typography><Typography className={classes.baseBoxName}>XPAND</Typography></Box>
                            <Box boxShadow={24} className={classes.baseStepCounter}><Typography style={{fontSize: '2.25rem', fontWeight: 'bolder', color: 'white'}} >E</Typography></Box>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={classes.aboutUs}>
                <Grid container className={classes.page} style={{padding: '1rem 0.25rem', display: 'flex', alignItems: 'center'}}>
                    <Grid item sm={12} md={5}><img src="https://imgur.com/w2OKfpr.jpg" alt="" style={{maxWidth: '96%', borderRadius: '20px', padding: '2%'}} /></Grid>
                    <Grid item sm={12} md={7} style={{padding: '1rem 0.25rem'}}>
                        <Typography className={classes.containerHeading}>Welcome to</Typography>
                        <Typography className={`${classes.containerTagLine} ${classes.aboutUsLineTwo}`}  >Easy Commerce Solutions</Typography>
                        <Typography className={classes.aboutUsLineThree}>We are bringing to you an ultra modern E-Commerce solution, built on latest technology. This makes your website fast, attractive, customer friendly, easy to operate and automate daily functionalities which helps you to focus on the core business and its profitability.</Typography>
                        <Grid container>
                            <Grid item xs={12} md={5}>
                                <Typography className={classes.aboutUsSubHeading}>Our Vision</Typography>
                                <Typography className={classes.aboutUsSubText}>Our vision is to become the first choice for tech expertise to every MSME who wants to ride on the success of E-Commerce</Typography>
                                <Typography className={classes.aboutUsSubHeading}>Our Mission</Typography>
                                <Typography className={classes.aboutUsSubText}>We have brought to you the latest and fastest possible solution to launch, promote, monitor and grow an E-Commerce business. We are with you on every step of process.</Typography>
                            </Grid>
                            <Grid item xs={12} md={7} style={{display: 'flex', alignItems: 'center'}} ><img src="https://imgur.com/7WLNjX6.jpg" alt="" style={{maxWidth: '100%', borderRadius: '10px'}} /></Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={classes.services}>
                <Grid container className={`${classes.page} ${classes.servicesWhitePage}`}>
                    <Grid item xs={12} md={4}>
                        <Typography className={classes.containerHeading}>Our Services</Typography>
                        <Typography className={classes.containerTagLine}>E-Commerce Business Solutions</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Hidden mdDown><Typography className={classes.servicesLineFive}> Apart from the basics, we provide you the following special features</Typography></Hidden>
                        <Box style={{display: 'flex'}}>
                            <Box style={{padding: '0 0.25rem'}}>
                                <Typography className={classes.servicesBulletPt}> <DoubleArrowIcon className={classes.servicesBulletPtIcon} />Create and sync shipments with shipping partner.</Typography>
                                <Typography className={classes.servicesBulletPt}> <DoubleArrowIcon className={classes.servicesBulletPtIcon} />Re-marketing of products seen by customers.</Typography>
                                <Typography className={classes.servicesBulletPt}> <DoubleArrowIcon className={classes.servicesBulletPtIcon} />Automatic Cart Abandon email to increase conversion.</Typography>
                            </Box>
                            <Box style={{padding: '0 0.25rem'}}>
                                <Typography className={classes.servicesBulletPt}> <DoubleArrowIcon className={classes.servicesBulletPtIcon} />Sync inventory with Google Shopping Ads.</Typography>
                                <Typography className={classes.servicesBulletPt}> <DoubleArrowIcon className={classes.servicesBulletPtIcon} />Sync inventory with Facebook Shop.</Typography>
                                <Typography className={classes.servicesBulletPt}> <DoubleArrowIcon className={classes.servicesBulletPtIcon} />Extended support for Google Analytics.</Typography>
                            </Box>
                        </Box>
                            
                        
                    </Grid>
                    <Grid container className={classes.serviceBoxContainer}>
                        <Grid item xs={12} md={6} lg={4}>
                            <Box className={classes.servicesBox} boxShadow={8}>
                                <Box className={classes.serviceBoxImg} ><img src="https://imgur.com/aBY3Oqa.jpg" alt="" style={{maxWidth: '96%', borderRadius: '10px', padding: '2%'}} /></Box>
                                <Typography className={classes.serviceBoxName}>E-Commerce Website</Typography>
                                <Typography className={classes.serviceBoxDesc}>Take your business online</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Box className={classes.servicesBox} boxShadow={8}>
                                <Box className={classes.serviceBoxImg} ><img src="https://imgur.com/hmW465L.jpg" alt="" style={{maxWidth: '96%', borderRadius: '10px', padding: '2%'}} /></Box>
                                <Typography className={classes.serviceBoxName}>Domain Name</Typography>
                                <Typography className={classes.serviceBoxDesc}>Choose your identity</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Box className={classes.servicesBox} boxShadow={8}>
                                <Box className={classes.serviceBoxImg} ><img src="https://imgur.com/HQJuVkR.jpg" alt="" style={{maxWidth: '96%', borderRadius: '10px', padding: '2%'}} /></Box>
                                <Typography className={classes.serviceBoxName}>Personalised Email</Typography>
                                <Typography className={classes.serviceBoxDesc}>Becoming a brand</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Box className={classes.servicesBox} boxShadow={8}>
                                <Box className={classes.serviceBoxImg} ><img src="https://imgur.com/JTEb2Gt.jpg" alt="" style={{maxWidth: '96%', borderRadius: '10px', padding: '2%'}} /></Box>
                                <Typography className={classes.serviceBoxName}>Email Marketing</Typography>
                                <Typography className={classes.serviceBoxDesc}>Stay connected to customers</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Box className={classes.servicesBox} boxShadow={8}>
                                <Box className={classes.serviceBoxImg} ><img src="https://imgur.com/X6y5can.jpg" alt="" style={{maxWidth: '96%', borderRadius: '10px', padding: '2%'}} /></Box>
                                <Typography className={classes.serviceBoxName}>Digital Marketing</Typography>
                                <Typography className={classes.serviceBoxDesc}>Be where your customers are</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Box className={classes.servicesBox} boxShadow={8}>
                                <Box className={classes.serviceBoxImg} ><img src="https://imgur.com/INk6SHI.jpg" alt="" style={{maxWidth: '96%', borderRadius: '10px', padding: '2%'}} /></Box>
                                <Typography className={classes.serviceBoxName}>Maintenance & Support</Typography>
                                <Typography className={classes.serviceBoxDesc}>Live a worry free life</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={classes.themesContainer} >
                <Grid item xs={12}><Typography className={classes.containerHeading}>Theme Store</Typography></Grid>
                <Grid item xs={12}><Typography className={classes.containerTagLine}>Choose the best theme for your store</Typography></Grid>
                <Grid container>
                    <Grid item xs={12} md={6} lg={4} className={classes.themeCell} >
                        <img className={classes.themeCellImg} src="https://img.freepik.com/premium-photo/homemade-jam-supermarket_1098-9417.jpg" alt="" />
                        <Typography className={classes.themeName}>Electronics</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} className={classes.themeCell} >
                        <img className={classes.themeCellImg} src="https://img.freepik.com/free-photo/tomato-assortment-supermarket_1098-15188.jpg" alt="" />
                        <Typography className={classes.themeName}>Groceries</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} className={classes.themeCell} >
                        <img className={classes.themeCellImg} src="https://img.freepik.com/free-photo/asian-woman-with-red-apple_1098-21831.jpg" alt="" />
                        <Typography className={classes.themeName}>Fashion</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} className={classes.themeCell} >
                        <img className={classes.themeCellImg} src="https://img.freepik.com/free-photo/woman-pushing-shopping-cart-shelves-supermarket_342744-1128.jpg" alt="" />
                        <Typography className={classes.themeName}>Kids</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} className={classes.themeCell} >
                        <img className={classes.themeCellImg} src="https://img.freepik.com/free-photo/happy-young-family-standing-with-trolley_171337-2386.jpg" alt="" />
                        <Typography className={classes.themeName}>Furniture</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} className={classes.themeCell} >
                        <img className={classes.themeCellImg} src="https://img.freepik.com/premium-photo/homemade-jam-supermarket_1098-9417.jpg" alt="" />
                        <Typography className={classes.themeName}>Home Decor</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} className={classes.themeCell} >
                        <img className={classes.themeCellImg} src="https://img.freepik.com/free-photo/asian-woman-with-red-apple_1098-21831.jpg" alt="" />
                        <Typography className={classes.themeName}>Pet Store</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} className={classes.themeCell} >
                        <img className={classes.themeCellImg} src="https://img.freepik.com/free-photo/tomato-assortment-supermarket_1098-15188.jpg" alt="" />
                        <Typography className={classes.themeName}>Fashion Accessories</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} className={classes.themeCell} >
                        <img className={classes.themeCellImg} src="https://img.freepik.com/free-photo/beautiful-female-with-shopping-cart-walking-by-supermarket-freezer-choosing-what-buy_342744-1120.jpg" alt="" />
                        <Typography className={classes.themeName}>Sports</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={classes.pwaContainer}>
                <Grid item xs={12}><Typography className={classes.containerHeading}>Progressive web app</Typography></Grid>
                <Grid item xs={12}><Typography className={classes.containerTagLine} style={{color: 'white'}}>3 In 1 solution for your growth</Typography></Grid>
                <Grid container className={`${classes.page} ${classes.pwaColumnContainer}`}>
                    <Grid item sm={12} lg={4} className={classes.pwaColImg}><Box style={{margin: '0 1rem'}}><img src="https://imgur.com/R3YqxNp.png" alt="" style={{maxWidth: '90%'}} /></Box></Grid>
                    <Grid item sm={12} lg={4} className={classes.pwaFeatureColOne}>
                        <Box className={classes.pwaFeatureBox} >
                            <AddCommentIcon className={classes.pwaIcon} />
                            <Typography className={classes.pwaFeatureName} >Works Offline</Typography>
                            <Typography className={classes.pwaFeatureDesc} >The capability to operate offline or in compromised networks makes PWAs much more convenient than websites, which require a proper internet connection. It stores important features and information automatically, and allows users to access it without an internet connection.</Typography>
                        </Box>
                        <Box className={classes.pwaFeatureBox}>
                            <AddCommentIcon className={classes.pwaIcon} />
                            <Typography className={classes.pwaFeatureName} >App like look and feel</Typography>
                            <Typography className={classes.pwaFeatureDesc} >Now a days, mobile users prefer apps to browsers as they are more user-friendly, can operate offline and have a more attractive interface. PWAs provide an advanced user experience by combining the look and feel of mobile applications and the best of website performance.</Typography>
                        </Box>
                        <Box className={classes.pwaFeatureBox}>
                            <AddCommentIcon className={classes.pwaIcon} />
                            <Typography className={classes.pwaFeatureName} >Seemless Installation</Typography>
                            <Typography className={classes.pwaFeatureDesc} >Your users can totally skip the app aggregators like Google Play, App Store etc. and install the applications on their devices, right from the browser while browsing your website. This helps in reduced marketing cost and better Customer acquisition rate.</Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={12} lg={4} className={classes.pwaFeatureColTwo}>
                        <Box className={classes.pwaFeatureBox}>
                            <AddCommentIcon className={classes.pwaIcon} />
                            <Typography className={classes.pwaFeatureName} >Push Notification</Typography>
                            <Typography className={classes.pwaFeatureDesc} >Like native mobile applications, PWAs have access to device-specific functionality, such as push notifications. This capability can be performed in various ways and allows companies to make the best use of content advertising.</Typography>
                        </Box>
                        <Box className={classes.pwaFeatureBox}>
                            <AddCommentIcon className={classes.pwaIcon} />
                            <Typography className={classes.pwaFeatureName} >Low Development Cost</Typography>
                            <Typography className={classes.pwaFeatureDesc} >PWAs do not require different versions for various devices; a single progressive app meets the requirements of all endpoints on which it operates. So, it significantly reduces the amount of efforts that developers provide, and as a result, the cost to create a PWA decreases. The cost is three or four times lower than that of a native mobile app.</Typography>
                        </Box>
                        <Box className={classes.pwaFeatureBox}>
                            <AddCommentIcon className={classes.pwaIcon} />
                            <Typography className={classes.pwaFeatureName} >Instant Updates</Typography>
                            <Typography className={classes.pwaFeatureDesc} >Whenever you roll-out a new feature, you don't have to ask your customers to update the app regularly. The latest features are always incorporated automatically, the next time they visit your website or PWA. This results in uniform user experience across all devices.</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={classes.featureContainer}>
                <Grid item xs={12}><Typography className={classes.containerHeading}>our top features</Typography></Grid>
                <Grid item xs={12}><Typography className={classes.containerTagLine}>choose us to make <Box component="span" className={classes.highlightWord}>SMART</Box> choice</Typography></Grid>
                <Grid container className={`${classes.featureBoxContainer} ${classes.page}`}>
                    <Grid item xs={12} md={6} lg={3}>
                        <Box className={classes.featureBox} boxShadow={8} >
                            <Typography className={classes.featureName}>Unlimited Coupons</Typography>
                            <Typography className={classes.featureDesc}>You can create unlimited coupons of different types, based on order value, product category, order item count and to specific customers as well.</Typography>
                            <Box className={classes.redCorner} boxShadow={8}><Typography style={{fontWeight: 'bold', fontSize: '2rem'}} >2</Typography></Box>
                            <Box className={classes.featureBoxBg}><img src="https://imgur.com/1fniKCb.png" alt="" style={{width: '100px'}} /></Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Box className={classes.featureBox} boxShadow={8} >
                            <Typography className={classes.featureName}>Fast rendering of Images</Typography>
                            <Typography className={classes.featureDesc}>Images has the most impact on the website speed. With our CDN enabled hosting. your will not feel any such issue and your website will render fast.</Typography>
                            <Box className={classes.redCorner} boxShadow={8}><Typography style={{fontWeight: 'bold', fontSize: '2rem'}} >3</Typography></Box>
                            <Box className={classes.featureBoxBg}><img src="https://imgur.com/c87soy9.png" alt="" style={{width: '100px'}} /></Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Box className={classes.featureBox} boxShadow={8} >
                            <Typography className={classes.featureName}>Split Order to Shipments</Typography>
                            <Typography className={classes.featureDesc}>You need not to worry about packaging boxes for orders with multiple items. You can easily create multiple shipments per order with individual delivery status.</Typography>
                            <Box className={classes.redCorner} boxShadow={8}><Typography style={{fontWeight: 'bold', fontSize: '2rem'}} >4</Typography></Box>
                            <Box className={classes.featureBoxBg}><img src="https://imgur.com/IcFWSbV.png" alt="" style={{width: '100px'}} /></Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Box className={classes.featureBox} boxShadow={8} >
                            <Typography className={classes.featureName}>Custom COD Rules</Typography>
                            <Typography className={classes.featureDesc}>Set your own rules to enable/disable COD for expensive products, high order values, certain set of customers, for specific pincodes or for certain time. </Typography>
                            <Box className={classes.redCorner} boxShadow={8}><Typography style={{fontWeight: 'bold', fontSize: '2rem'}} >1</Typography></Box>
                            <Box className={classes.featureBoxBg}><img src="https://imgur.com/OVLmlWM.png" alt="" style={{width: '100px'}} /></Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Box className={classes.featureBox} boxShadow={8} >
                            <Typography className={classes.featureName}>Leverage power of Pincode</Typography>
                            <Typography className={classes.featureDesc}>Fetch City/State from pincode for delivery address or set shipping charges for different pincodes or disable delivery or COD in certain areas.</Typography>
                            <Box className={classes.redCorner} boxShadow={8}><Typography style={{fontWeight: 'bold', fontSize: '2rem'}} >5</Typography></Box>
                            <Box className={classes.featureBoxBg}><img src="https://imgur.com/DSprL17.png" alt="" style={{width: '100px'}} /></Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Box className={classes.featureBox} boxShadow={8} >
                            <Typography className={classes.featureName}>Product Recommendations</Typography>
                            <Typography className={classes.featureDesc}>On product detail page, customers will see the product in similar price range, category, colors and other matching attributes to choose from. </Typography>
                            <Box className={classes.redCorner} boxShadow={8}><Typography style={{fontWeight: 'bold', fontSize: '2rem'}} >6</Typography></Box>
                            <Box className={classes.featureBoxBg}><img src="https://imgur.com/gx2y8YA.png" alt="" style={{width: '100px'}} /></Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Box className={classes.featureBox} boxShadow={8} >
                            <Typography className={classes.featureName}>GST Compliant</Typography>
                            <Typography className={classes.featureDesc}>Just enter the HSN Code and selling price with the product and the system will automatically pickup the suitable tax slab at the time of processing the orders</Typography>
                            <Box className={classes.redCorner} boxShadow={8}><Typography style={{fontWeight: 'bold', fontSize: '2rem'}} >7</Typography></Box>
                            <Box className={classes.featureBoxBg}><img src="https://imgur.com/Zk6kps6.png" alt="" style={{width: '100px'}} /></Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Box className={classes.featureBox} boxShadow={8} >
                            <Typography className={classes.featureName}>Lightning Fast</Typography>
                            <Typography className={classes.featureDesc}>Your store will be equipped with all modern features, without the burden of extra plugins for each micro functionality. Hence, the site will load amazingly faster.</Typography>
                            <Box className={classes.redCorner} boxShadow={8}><Typography style={{fontWeight: 'bold', fontSize: '2rem'}} >8</Typography></Box>
                            <Box className={classes.featureBoxBg}><img src="https://imgur.com/dJvx7g3.png" alt="" style={{width: '100px'}} /></Box>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            
            <Grid className={classes.footer}>
                <Grid container className={`${classes.page}`}>
                    <Grid container className={`${classes.contactBanner}`}>
                        <Grid item xs={12} md={4}>
                            <a className={classes.contactLinkNavbar} href="tel:+919958344477" alt="" style={{color: 'black'}}>
                                <Box className={classes.contactBox}>
                                    <CallIcon className={classes.contactIcon} />
                                    <Box>
                                        <Typography className={classes.contactTextLineOne} >Call Us</Typography>
                                        <Typography className={classes.contactTextTwo} >+91-9958344477</Typography>
                                    </Box>
                                </Box>
                            </a>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <a className={classes.contactLinkNavbar} href="mailto:easycommercesol@gmail.com" alt="" style={{color: 'black'}}>
                                <Box className={classes.contactBox}>
                                    <MailIcon className={classes.contactIcon} />
                                    <Box>
                                        <Typography className={classes.contactTextLineOne} >Mail Us</Typography>
                                        <Typography className={classes.contactTextTwo} >easycommercesol@gmail.com</Typography>
                                    </Box>
                                </Box>
                            </a>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <a className={classes.contactLinkNavbar} href="https://wa.me/919958344477?text=Hi,%20I%20would%20like%20to%20know%20more%20about%20your%20products%20and%20services" alt="" style={{color: 'black'}}>
                                <Box className={classes.contactBox} style={{border: 0}} >
                                    <WhatsAppIcon className={classes.contactIcon} />
                                    <Box>
                                        <Typography className={classes.contactTextLineOne} >Whatsapp Us</Typography>
                                        <Typography className={classes.contactTextTwo} >+91-9958344477</Typography>
                                    </Box>
                                </Box>
                            </a>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={`${classes.page} ${classes.footerContentArea}`} >
                    <Hidden xlDown>
                    <Grid item xs={12} md={6} className={classes.footerColOne}>
                        <Box className={classes.footerCol}>
                            <img src="https://i.imgur.com/bQirng8.png" alt="" style={{maxWidth: '350px'}} />
                            <Typography style={{color: 'grey', maxWidth: '600px', padding: '1rem', lineHeight: '2.25rem'}}>The capability to operate offline or in compromised networks makes PWAs much more convenient than websites. We enable the world’s leading companies with cutting-edge digital & IT service as a competitive advantage ahead of schedule.</Typography>
                        </Box>
                        <Box className={classes.footerCol}>
                            <Typography style={{color: 'white', fontWeight: 'bolder', paddingBottom: '1rem', textDecoration: 'underline'}} >Social Presence</Typography>
                            <FacebookIcon className={classes.socialIcon} />
                            <InstagramIcon className={classes.socialIcon} />
                            <TwitterIcon className={classes.socialIcon} />
                            <PinterestIcon className={classes.socialIcon} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3} className={classes.footerColTwo}>
                        <Box className={classes.footerCol}>
                            <Typography className={classes.footerColHead}>Solutions</Typography>
                            <Typography className={classes.footerColLink}>Inventory</Typography>
                            <Typography className={classes.footerColLink}>Marketplace</Typography>
                            <Typography className={classes.footerColLink}>Mobile Apps</Typography>
                            <Typography className={classes.footerColLink}>Dropshipping</Typography>
                            <Typography className={classes.footerColLink}>PWA</Typography>
                            <Typography className={classes.footerColLink}>Print On Demand</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3} className={classes.footerColThree}>
                        <Box className={classes.footerCol}>
                            <Typography className={classes.footerColHead}>Services</Typography>
                            <Typography className={classes.footerColLink}>Domain Name</Typography>
                            <Typography className={classes.footerColLink}>Personalised Email</Typography>
                            <Typography className={classes.footerColLink}>Email Marketing</Typography>
                            <Typography className={classes.footerColLink}>Digital Marketing</Typography>
                            <Typography className={classes.footerColLink}>Product Photoshoot</Typography>
                            <Typography className={classes.footerColLink}>Make Payment</Typography>
                        </Box>
                    </Grid>
                    </Hidden>
                </Grid>
                <Box className={classes.bottomLine} >
                    <Typography style={{fontWeight: 'bold'}} >All rights reserved. Easy Commerce Solutions</Typography>
                </Box>
            </Grid>
        </>
    );
}

export default Home;